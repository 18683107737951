import { Controller } from '@hotwired/stimulus';
import Decimal from 'decimal.js';

export default class extends Controller {
  static targets = [
    'valorTimbre',
    'cantidadSolicitada',
    'montoPagar',
    'tipoTimbre',
  ];

  fetchTipoTimbreValue() {
    const timbresUrl = new URL(this.data.get('tipoTimbreUrl'));
    timbresUrl.searchParams.append('id', this.tipoTimbre);

    fetch(timbresUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.valor) {
          this.valorTimbreTarget.value = data.valor;
        }
      })
      .catch(() => {});
  }

  calcularMontoPagar() {
    if (
      this.valorTimbre.greaterThanOrEqualTo(0) &&
      this.cantidadSolicitada.greaterThanOrEqualTo(0)
    ) {
      this.montoPagarTarget.value = this.valorTimbre
        .times(this.cantidadSolicitada)
        .toDP(2)
        .toString();
    } else {
      this.montoPagarTarget.value = new Decimal(0).toDP(2).toString(2);
    }
  }

  // private

  get tipoTimbre() {
    let e = this.tipoTimbreTarget;
    return e.options[e.selectedIndex].value;
  }

  get valorTimbre() {
    if (Number.isNaN(parseInt(this.valorTimbreTarget.value))) {
      return new Decimal('0');
    }
    return new Decimal(this.valorTimbreTarget.value);
  }
  get cantidadSolicitada() {
    if (Number.isNaN(parseInt(this.cantidadSolicitadaTarget.value))) {
      return new Decimal('0');
    }
    return new Decimal(this.cantidadSolicitadaTarget.value);
  }
  set montoPagar(value) {
    this.montoPagarTarget.value = value.toDP(2);
  }
}
