import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['activator', 'template'];
  static classes = ['open', 'closed'];
  static values = {
    placement: String,
    active: Boolean,
  };

  connect() {
    const clonedTemplate = this.templateTarget.content.cloneNode(true);
    this.popoverTarget = clonedTemplate.firstElementChild;

    document.body.appendChild(clonedTemplate);

    this.popper = createPopper(this.activatorTarget, this.popoverTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: [
              'top-start',
              'bottom-start',
              'top-end',
              'bottom-end',
            ],
          },
        },
      ],
    });
    if (this.activeValue) {
      this.show();
    }
  }

  async toggle() {
    console.log('toggle fired');
    console.log(this.closedClass);
    console.log(this.openClass);
    console.log(this.popoverTarget.classList);
    this.popoverTarget.classList.toggle(this.closedClass);
    this.popoverTarget.classList.toggle(this.openClass);
    await this.popper.update();
  }

  async show() {
    console.log('show fired');
    this.popoverTarget.classList.remove(this.closedClass);
    this.popoverTarget.classList.add(this.openClass);
    await this.popper.update();
  }

  hide(event) {
    if (
      !this.element.contains(event.target) &&
      !this.popoverTarget.contains(event.target) &&
      !this.popoverTarget.classList.contains(this.closedClass)
    ) {
      this.forceHide();
    }
  }

  forceHide() {
    console.log('forceHide fired');
    this.popoverTarget.classList.remove(this.openClass);
    this.popoverTarget.classList.add(this.closedClass);
  }
}
