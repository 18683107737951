import { Controller } from '@hotwired/stimulus';

const tipoPersonas = ['natural', 'juridica'];
// Connects to data-controller="persona-form"
export default class extends Controller {
  static targets = ['activator', 'form', 'naturalField', 'juridicaField'];

  connect() {
    if (this.formTarget !== undefined) {
      this.showForm();
    }
  }

  showForm() {
    if (tipoPersonas.includes(this.activatorTarget.value)) {
      this.formTarget.classList.remove('hidden');
      this.formTarget.classList.add('visible');
    } else {
      this.formTarget.classList.remove('visible');
      this.formTarget.classList.add('hidden');
    }
    this.showNaturalFields();
    this.showJuridicaFields();
  }

  showNaturalFields() {
    if (this.activatorTarget.value === 'natural') {
      this.naturalFieldTargets.forEach((element, index) => {
        element.classList.add('visible');
        element.classList.remove('hidden');
      });
    } else {
      this.naturalFieldTargets.forEach((element, index) => {
        element.classList.remove('visible');
        element.classList.add('hidden');
      });
    }
  }

  showJuridicaFields() {
    if (this.activatorTarget.value === 'juridica') {
      this.juridicaFieldTargets.forEach((element, index) => {
        element.classList.add('visible');
        element.classList.remove('hidden');
      });
    } else {
      this.juridicaFieldTargets.forEach((element, index) => {
        element.classList.remove('visible');
        element.classList.add('hidden');
      });
    }
  }
}
