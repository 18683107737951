import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (!this.isPreview) {
      // TO-DO: Implement previews for components
    }
    console.log('notification connected');
    setTimeout(() => {
      this.close();
    }, 5000);
  }

  close() {
    console.log('requested to close');
    // Remove with transition
    this.element.classList.remove(
      'transform',
      'ease-out',
      'duration-300',
      'translate-y-2',
      'opacity-0',
      'sm:translate-y-0',
      'sm:translate-x-2',
      'translate-y-0',
      'sm:translate-x-0'
    );
    this.element.classList.add('ease-in', 'duration-100');

    // Trigger transition
    setTimeout(() => {
      this.element.classList.add('opacity-y-0');
    }, 100);

    // Remove element after transition
    setTimeout(() => {
      this.element.remove();
    }, 300);
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }
}
